<template>
    <LayoutReconciliationTab pageType="kits"/>
</template>

<script>
import LayoutReconciliationTab from '../layouts/LayoutReconciliationTab.vue';
export default {
    name: 'PageReconciliationKits',
    components: {
        LayoutReconciliationTab
    }
}
</script>